//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyMessage from '@/components/EmptyMessage'
import Achievement from '@/components/Achievements/Achievement'
export default {
    name: "AchievementsList",
    props: [
        'name',
        'entity_type_id', 
        'entity_id'
    ],
    data() {
        return {
            isEmpty: true,
            isOwner: true,
            achievements: null,
            currentPage: 1,
            maxNumberOfPages: 1,
            addAchievementDialog: false,
            title: '',
            description: '',
            edit_id: null,
            isTitleValid: true,
            isDescriptionValid: true
        }
    },
    components: {
        EmptyMessage,
        Achievement
    },
    methods: {
        addAchievement() {
            if (this.title === '' || this.title === null) {
                this.isTitleValid = false;
                return;
            }
            this.$http({
                url: 'achievements',
                method: 'POST',
                data: {
                    entity_type_id: this.entity_type_id,
                    entity_id: this.entity_id,
                    title: this.title,
                    description: this.description
                }
            }).then((response) => {
                if (response.data['status'] == 'achievement_created') {
                    this.$q.notify({
                        message: 'A new achievement has been added.'
                    });
                    this.title = '';
                    this.description = '';
                    this.addAchievementDialog = !this.addAchievementDialog;
                    this.isTitleValid = true;
                    this.loadAchievements();
                } else {
                    this.$q.notify({
                        message: 'There has been an error while trying to add new achievement. Please try again later.',
                        color: 'error'
                    });
                    this.title = '';
                    this.description = '';
                }
            });
        },
        showAddAchievement() {
            this.addAchievementDialog = !this.addAchievementDialog;
        },
        loadAchievements() {
            if (this.entity_type_id == 0) {
                this.$http({
                    url: 'users/' + this.entity_id + '/achievements/' + this.currentPage,
                    method: 'GET'
                }).then((response) => {
                    if (response.data['status'] == 'achievements_retrieved') {
                        this.achievements = response.data['body']['achievements'];
                        this.currentPage = response.data['body']['pagination']['currentPage'];
                        this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages'];
                        this.isOwner = response.data['body']['isOwner'];
                        this.isEmpty = false;
                    }
                    if (response.data['status'] == 'achievements_empty') {
                        this.isEmpty = true;
                        this.isOwner = response.data['body']['isOwner'];
                    } 
                });
                return;
            }
                this.$http({
                    url: 'achievements/' + this.entity_type_id + '/' + this.entity_id + '/' + this.currentPage,
                    method: 'GET'
                }).then((response) => {
                    if (response.data['status'] == 'achievements_retrieved') {
                        this.achievements = response.data['body']['achievements'];
                        this.currentPage = response.data['body']['pagination']['currentPage'];
                        this.maxNumberOfPages = response.data['body']['pagination']['maxNumberOfPages'];
                        this.isOwner = response.data['body']['isOwner'];
                        this.isEmpty = false;
                    }
                    if (response.data['status'] == 'achievements_empty') {
                        this.isEmpty = true;
                        this.isOwner = response.data['body']['isOwner'];
                    } 
                });
                return;            
        }
    },
    mounted() {
        this.loadAchievements();
    }
}
