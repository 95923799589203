//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "Achievement",
    props: [
        'achievement'
    ],
    data() {
      return {
        title: this.achievement.title,
        description: this.achievement.description,
        id: this.achievement.id,
        entity_type_id: this.achievement.entity_type_id,
        entity_id: this.achievement.entity_id,
        editAchievement: false,
        deleteAchievement: false
      }
    },
    methods: {
      delet() {
        this.$http({
          url: 'achievements/' + this.id,
          method: 'DELETE'
        }).then((response) => {
          if (response.data['status'] == 'achievement_deleted') {
            this.$q.notify({
              message: 'The achievement has been successfully deleted.',
              color: 'positive'
            });
            this.$emit('dirty');
          } else {
            this.$q.notify({
              message: 'There has been an error while trying to delete. Please try again later.',
              color: 'negative'
            });
          }
        })
      },
      edit() {
        this.editAchievement = !this.editAchievement;
      },
      save() {
        this.$http({
          url: 'achievements/' + this.id,
          method: 'PUT',
          data: {
            title: this.title, 
            description: this.description
          }
        }).then((response) => {
          if (response.data['status'] == 'achievement_updated') {
            this.$q.notify({
              message: 'The achievement has been successfully updated.',
              color: 'positive'
            });
            this.$emit('dirty');
          } else {
            this.$q.notify({
              message: 'There has been an error while trying to save. Please try again later.',
              color: 'negative'
            });
          }
        })
      }
    }
}
